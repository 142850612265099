import React from "react";

const QuickLinks = () => {
  return (
    <div>
      <div
        className="d-block d-sm-none fixed-bottom"
        id="mm-1"
        style={{
          marginBottom: "-20px",
        }}
      >
        <div className="sepl-bg-dark">
          <div className="container">
            <ul
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Remove the commented HTML code and replace it with JSX */}
              {/* <li style={{ listStyle: "none" }}>
                <a href="telphone" style={{ textDecoration: "none" }}>
                  <i className="bi bi-telephone-fill">:70262 22296</i>
                </a>
              </li>
              <li style={{ listStyle: "none" }}>
                <a href="Email" style={{ textDecoration: "none" }}>
                  <i className="bi bi-envelope">
                    :principalactamritaschool@gmail.com
                  </i>
                </a>
              </li> */}
              <li
                style={{ listStyle: "none", height: "55px", fontSize: "30px" }}
              >
                <a
                  href="https://www.facebook.com/profile.php?id=100091624549336"
                  target="_blank"
                >
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
              <li
                style={{ listStyle: "none", height: "55px", fontSize: "30px" }}
              >
                <a
                  href="https://www.linkedin.com/in/ACT Amrita-international-school-bagalkot-5b5854272/"
                  target="_blank"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
              </li>
              <li
                className="pt-3"
                style={{ listStyle: "none", height: "75px" }}
              >
                <a href="/contactus" className="mx-auto">
                  <button type="button" className="btn btn-primary">
                    Contactus
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Uncomment this section for the device view */}
      <div className="d-none d-lg-block" id="mm-1">
        <div className="sepl-bg-dark">
          <div className="container">
            <ul
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <li style={{ listStyle: "none", height: "30px" }}>
                <i className="bi bi-telephone-fill">:08354-200276</i>
              </li>
              <li style={{ listStyle: "none", height: "30px" }}>
                <i className="bi bi-envelope">
                  :principalactamritaschool@gmail.com
                </i>
              </li>
              <li
                style={{ listStyle: "none", height: "41px", fontSize: "28px" }}
              >
                <a
                  href="https://www.facebook.com/profile.php?id=100091624549336"
                  target="_blank"
                >
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
              <li
                style={{ listStyle: "none", height: "41px", fontSize: "28px" }}
              >
                <a
                  href="https://www.linkedin.com/in/ACT Amrita-international-school-bagalkot-5b5854272/"
                  target="_blank"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
              </li>
              <li style={{ listStyle: "none", fontSize: "21px" }}>
                <a href="/contactus" className="mx-auto">
                  <button type="button" className="btn btn-primary">
                    Contactus
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;
