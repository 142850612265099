import React from "react";
import "../modules/TableFiles.css";

const WebsiteInfo = () => {
  return (
    <div className="m-5 filesdiv">
      <h1>Website Information</h1>
      <table>
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>Information</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Url of School Website</td>
            <td>
              <a
                href="https://actais.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://actais.in
              </a>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Details of Curriculum</td>
            <td>NCERT</td>
          </tr>
          <tr>
            <td>3</td>
            <td>School Managing Committee Information</td>
            <td>
              <a href="https://actais.in/files/infosmc.pdf" target="_blank">
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Transfer Certificate Sample</td>
            <td>
              <a href="https://actais.in/files/infotc.pdf" target="_blank">
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Norms followed for fixing Fee Structure</td>
            <td>
              <a
                href="https://actais.in/files/infofeenorms.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Self Declaration of the School</td>
            <td>
              <a
                href="https://actais.in/files/infoselfdeclaration.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Affidavit for Non-Proprietary Character of the School</td>
            <td>
              <a
                href="https://actais.in/files/infoaffidavit.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Affiliation Status</td>
            <td>
              <strong>Sought For Secondary School Affiliation</strong>
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Details of Infrastructure</td>
            <td>
              <a
                href="https://actais.in/files/infoschooinfra.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Details of Teachers Including Qualification/></td>
            <td>
              <a
                href="https://actais.in/files/infostaffdetails.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>The Number of Students Classwise</td>
            <td>
              <a
                href="https://actais.in/files/infostudentsdetails.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>

          <tr>
            <td>12</td>
            <td>
              Postal Address And Email;
              <br />
              Telephone No’s of School
            </td>
            <td>
              ACT Amrita International school,<br></br> Dr H M Shirur Campus
              Sector no-110,Road no 17 <br></br> Navanagar, Bagalkot-587104,
              Karnataka, India<br></br>
              Email: principalactamritaschool@gmail.com <br></br> PhoneNo:
              08354-200276
            </td>
          </tr>

          <tr>
            <td>13</td>
            <td>Academic Calendar of the School</td>
            <td>
              <a href="https://actais.in/files/calendar.pdf" target="_blank">
                View Document
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WebsiteInfo;
