import "./Navigation.css";
import { NavLink, useNavigate } from "react-router-dom";
import React from "react";

const Navigation = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top seplnavbar">
        <div className="container-fluid ">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <img
            alt="actlogo.png"
            src="images/logo/actais.png"
            className="logo"
          />
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item pe-5 pl-91">
                <NavLink
                  to={"/"}
                  className={"nav-link "}
                  style={{ color: "#ffffff" }}
                >
                  Home
                </NavLink>
              </li>

              {/* About Us Dropdown */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarAboutDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ color: "white", paddingRight: "65px" }}
                >
                  About Us
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarAboutDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="/why-aais">
                      Why AAIS
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/act-vpm-synergy">
                      ACT-VPM Synergy
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/welcome-message-from-our-principal"
                    >
                      Welcome message from our principal
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/leadershipteam">
                      Leadership Team
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item pe-5">
                <NavLink
                  to={"/programs"}
                  className={"nav-link"}
                  style={{ color: "#ffffff" }}
                >
                  Programs
                </NavLink>
              </li>

              <li className="nav-item pe-5">
                <NavLink
                  to={"/registration"}
                  className={"nav-link"}
                  style={{ color: "#ffffff" }}
                >
                  Registration
                </NavLink>
              </li>

              <li className="nav-item pe-5">
                <NavLink
                  to={"/multimedia"}
                  className={"nav-link"}
                  style={{ color: "#ffffff" }}
                >
                  Multimedia
                </NavLink>
              </li>

              <li className="nav-item pe-5 navmiddle">
                <NavLink
                  to={"/faqs"}
                  className={"nav-link"}
                  style={{ color: "#ffffff" }}
                >
                  FAQs
                </NavLink>
              </li>

              {/* Public Mandatory Disclosure Dropdown */}
              <li className="nav-item dropdown pe-5 navmiddle">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarMandatoryDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ color: "#ffffff" }}
                >
                  Public Mandatory Disclosure
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarMandatoryDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="/mandatory-disclosure">
                      Mandatory Disclosure
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/website-information">
                      Website Information
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <a href="https://www.actedu.in/" target="_blank" rel="noreferrer">
            <img alt="school.png" src="images/logo/act.png" className="logo1" />
          </a>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
